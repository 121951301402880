var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDepartments),expression:"showDepartments"}],staticClass:"content-container bg-white pl-8 pr-6 py-3 text-black-default relative"},[_c('button',{staticClass:"text-size-5 absolute right-5 z-header text-legacy-blue-default flex gap-3 items-center capitalize",attrs:{"data-testid":"close-btn"},on:{"click":function($event){$event.preventDefault();return _vm.toggleDepartmentMenu(false)}}},[_vm._v("\n    "+_vm._s(_vm.$tc("close"))+"\n    "),_c('XMarkIcon',{attrs:{"size":"lg"}})],1),_vm._v(" "),_c('ol',{staticClass:"megamenu"},_vm._l((_vm.getMenuHierarchy),function(department,index){return _c('li',{key:`${department.taxonomy.slug}`,staticClass:"megamenu__item"},[_c('nuxt-link',{staticClass:"department-name-link",class:{
          'department-name-link--active': _vm.currentDepartmentIndex === index,
          'text-legacy-red-menu': department.taxonomy.name == 'Clearance',
        },attrs:{"to":_vm.taxonomyPath(department.taxonomy, [])},on:{"keydown":function($event){return _vm.keyboardUpdateCurrentDepartment($event, index)},"focus":function($event){$event.stopPropagation();return _vm.doNothing.apply(null, arguments)}},nativeOn:{"click":function($event){return _vm.linkSelected.apply(null, arguments)},"mouseover":function($event){$event.preventDefault();return _vm.updateCurrentDepartment(index)}}},[_c('span',[_vm._v("\n          "+_vm._s(_vm.taxonomyName(department.taxonomy))+"\n        ")])]),_vm._v(" "),_c('client-only',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            department.children !== undefined &&
            department.children.length &&
            _vm.currentDepartmentIndex === index &&
            _vm.isDesktop
          ),expression:"\n            department.children !== undefined &&\n            department.children.length &&\n            currentDepartmentIndex === index &&\n            isDesktop\n          "}],staticClass:"megamenu__panel",attrs:{"data-testid":department.taxonomy.slug},on:{"click":_vm.linkSelected}},[_c('div',{staticClass:"current-department-title"},[_c('nuxt-link',{staticClass:"current-department-title-link",class:{
                'text-legacy-red-menu':
                  department.taxonomy.name === 'Clearance',
              },attrs:{"to":_vm.taxonomyPath(department.taxonomy, []),"no-prefetch":""}},[_vm._v("\n              "+_vm._s(_vm.taxonomyName(department.taxonomy))+"\n            ")])],1),_vm._v(" "),_c('ul',{staticClass:"megamenu__links"},_vm._l((department.children),function(subDepartment){return _c('li',{key:_vm.generateListKey([
                  department.taxonomy.slug,
                  subDepartment.taxonomy.slug,
                ]),staticClass:"subdept-nav"},[_c('a',{staticClass:"hover:underline subdepartment-link",attrs:{"href":_vm.taxonomyPath(subDepartment.taxonomy, [
                    department.taxonomy.slug,
                  ])},on:{"click":function($event){$event.preventDefault();_vm.$router.push(
                    _vm.taxonomyPath(subDepartment.taxonomy, [
                      department.taxonomy.slug,
                    ])
                  )}}},[_vm._v(_vm._s(_vm.taxonomyName(subDepartment.taxonomy))+"\n              ")]),_vm._v(" "),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(subDepartment.children.length),expression:"subDepartment.children.length"}],staticClass:"megamenu__sub-menu"},_vm._l((subDepartment.children),function(subDepartmentChild){return _c('li',{key:_vm.generateListKey([
                      department.taxonomy.slug,
                      subDepartment.taxonomy.slug,
                      subDepartmentChild.taxonomy.slug,
                    ])},[_c('a',{staticClass:"font-light hover:underline subdepartment-child-link",attrs:{"href":_vm.taxonomyPath(subDepartmentChild.taxonomy, [
                        department.taxonomy.slug,
                      ])},on:{"click":function($event){$event.preventDefault();_vm.$router.push(
                        _vm.taxonomyPath(subDepartmentChild.taxonomy, [
                          department.taxonomy.slug,
                        ])
                      )}}},[_vm._v(_vm._s(_vm.taxonomyName(subDepartmentChild.taxonomy)))])])}),0)])}),0)])])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }